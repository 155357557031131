<template>
    <v-container>
        <v-row>
            <v-col md="3">
                <h1>Admin Dashboard</h1>
            </v-col>
            <v-col>
                <v-text-field :loading="loading" append-inner-icon="mdi-magnify" density="compact" label="Search"
                    variant="solo" hide-details single-line @click:append-inner="onClick"></v-text-field>
            </v-col>
        </v-row>
        <v-sheet color="#0E0856" class="rounded-xl pa-2 d-flex justify-space-between">
            <v-card width="100%" class="ma-2 d-flex flex-column align-center">
                <div class="pa-2">
                    Manage Users
                </div>
                <div>
                    <!--Add Client Dialog Starts Here-->
                    <v-dialog max-width="500">
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" size="small" variant="text" outlined
                                class="ma-2 rounded-lg">Add New Client <v-icon size="20"
                                    class="ml-2">mdi-account-plus-outline</v-icon></v-btn>
                        </template>
                        <template v-slot:default="{ isActive }">
                            <v-card prepend-icon="mdi-account-plus" title="Add New User">
                                <v-card-text>
                                    <form @submit.prevent="submit">
                                        <v-text-field v-model="username" label="Username">

                                        </v-text-field>
                                        <v-text-field v-model="password" type="password" label="Password">

                                        </v-text-field>
                                        <v-text-field v-model="name" label="Name">

                                        </v-text-field>
                                        <v-text-field v-model="email" label="Email">

                                        </v-text-field>
                                        <v-text-field v-model="phone" label="Phone">

                                        </v-text-field>
                                        <v-select v-model="role" label="Role"
                                            :items="['Super_Admin', 'Client_Admin', 'Admin', 'App_User']">
                                        </v-select>
                                    </form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="isActive.value = false">
                                        Create
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                    <!--Add Client Dialog Ends Here-->
                    
                    <!--View All Users-->
                    <v-dialog fullscreen>
                        <template v-slot:activator="{ props: activatorProps}">
                            <v-btn v-bind="activatorProps" size="small" variant="text" class="ma-2 rounded-lg">View All <v-icon size="20"
                                class="ml-2">mdi-eye-outline</v-icon></v-btn>
                        </template>
                        <v-card>
                            <v-toolbar>
                                <v-btn icon="mdi-close"
                                @click="dialog = false"></v-btn>
                            </v-toolbar>
                            <v-toolbar-title>View All Users</v-toolbar-title>

                        </v-card>
                    </v-dialog>
                    <!--View All Users Ends-->
                </div>
            </v-card>
            <v-divider vertical></v-divider>
            <v-card width="100%" class="ma-2 d-flex flex-column align-center">
                <div class="pa-2">
                    Locations
                </div>
                <div>
                    <v-btn size="small" variant="text" class="ma-2 rounded-lg">Add Location <v-icon size="20"
                            class="ml-2">mdi-account-plus-outline</v-icon></v-btn>
                    <v-btn size="small" variant="text" class="ma-2 rounded-lg">View All <v-icon size="20"
                            class="ml-2">mdi-eye-outline</v-icon></v-btn>
                </div>
            </v-card>
            <v-divider vertical></v-divider>
            <v-card width="100%" class="ma-2 d-flex flex-column align-center">
                <div class="pa-2">
                    Notifications
                </div>
                <div>
                    <v-btn disabled variant="text" class="mr-1 pb-1" icon><v-icon>mdi-fuse-alert</v-icon></v-btn>
                    <v-btn variant="text" class="mr-1 pb-1" icon><v-icon>mdi-tanker-truck</v-icon></v-btn>
                    <v-btn disabled variant="text" class="mr-1 pb-1"
                        icon><v-icon>mdi-power-plug-battery-outline</v-icon></v-btn>
                    <v-btn variant="text" class="mr-1 pb-1" icon><v-icon>mdi-gauge-empty</v-icon></v-btn>
                </div>
            </v-card>
        </v-sheet>

        <v-card class="pa-5">
            Users Activity Trends Goes Here
        </v-card>
    </v-container>
</template>
<script>

export default {
    components: {
        
    },
    data() {
        return {
            dialog: false,
            username: '',
            password: '',
            name: '',
            email: '',
            phone: '',
        }
    }
}
</script>
<style scoped></style>