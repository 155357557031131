<template>
  <v-app-bar color="#0E0856">
    <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-img max-height="80" :src="require('../assets/volt_icon.png')"></v-img>
    <v-toolbar-title>
      Remote Monitoring System</v-toolbar-title>
    <v-spacer></v-spacer>    
  </v-app-bar>

  <v-navigation-drawer v-model="drawer" :location="$vuetify.display.mobile ? 'bottom' : undefined" temporary>
    <v-list app>
      <v-list-item to="/management">
        Home
      </v-list-item>
      <v-list-item to="/locations_management">
        Management
      </v-list-item>
      <v-list-item to='/reports'>
        Report
      </v-list-item>
      <v-list-item>
        Profile
      </v-list-item>
      <v-list-item to="/locations_detail">
        Location
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="logoutControl">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        title: 'Dashboard',
        to: '/dashboard',
      },
      {
        title: 'Reports',
        to: '/reports',
      },
      {
        title: 'Profile',
        to: '/profile',
      },

    ],
  }),
  methods: {
    logoutControl() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$store.commit("resetState");
          this.$router.push("/");
        })
        .catch((err) => {
          console.error("Error logging out:", err);
        });
    },

  },
  computed: {
    user() {
      const user = JSON.stringify(localStorage.getItem('user'));
      const role = user.user
      return role
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>