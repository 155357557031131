<template>
    <v-table height="70vh">
      <thead>
        <tr>
          <th class="text-left">
            Location
          </th>
          <th class="text-left">
            Power Source
          </th>
          <th class="text-left">
            Alert
          </th>
          <th class="text-left">
            Action
          </th>
        </tr>
      </thead>
      <tbody v-for="location in locations" :key="location.id">
        <tr>
          <td>{{location.name}}</td>
          <td>
            hello
          </td>
          <td><v-btn size="small" variant="text"><v-icon>mdi-storage-tank-outline</v-icon></v-btn></td>
          <td>
            <v-btn @click="showLocationDetail(location.id)" variant="text" >
            <v-icon>mdi-monitor-eye</v-icon>
            </v-btn>
        </td>
        </tr>
      </tbody>
    </v-table>
    <div>
      
    </div>
  </template>
  <script>
import api from '@/services/api';

  export default {
    data () {
      return {
        locations: [],
        cleanData: []
      }
    },
    async mounted () {
      this.getLocations();
      
    },    
    methods: {
      async getLocations () {
        try {
          const response =await api.getAllDevices();          
          this.locations = response.data.result;
          console.log('Location:', this.locations);                   
         // console.log('Information:', this.locations.flat(1));
        } catch (error) {
          console.error("Location list error", error)
        }
      },
      showLocationDetail(deviceId) {
        this.$emit('show-details', deviceId)
        console.log('Device Id: ', deviceId)
      }
    }
  }
</script>