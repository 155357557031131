import axios from 'axios';

// Create an Axios instance with default options
const apiClient = axios.create({
  baseURL: 'https://flespi.io',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `FlespiToken WZ2ucUAAx1DXYJD45BwCylRjgUooHyT18KPBmjDc76eJJGUuot6PSVrkotHGIphD`
  }
});

export default {
  getFlespiData(deviceId) {
    return apiClient.get(`/gw/calcs/1704940/devices/${deviceId}/intervals/all`);
  },
  getRefillData() {
    return apiClient.get('/gw/calcs/1704949/devices/5848395/intervals/all');
  },
  getAllDevices() {
    return apiClient.get('/gw/devices/all')
  },
  getMessages(deviceId) {
    return apiClient.get(`/gw/calcs/1706248/devices/${deviceId}/intervals/all`)
  },
  getLiveData() {
    return apiClient.get('/gw/devices/5848395/telemetry/device.name,din.1,din.2,din.3,escort.lls.value.2');
  },
  getGenOnDurations() {
    return apiClient.get('/gw/calcs/1705766/devices/5848395/intervals/all')
  },
  getReport() {
    return apiClient.get('/gw/calcs/1705766/devices/5848395/intervals/all')
  },
  summary() {
    return apiClient.get('/gw/calcs/1707374/devices/5848395/intervals/all')
  },
  async getLocation(deviceId) {    
    try{
      const response = await apiClient.get(`/gw/devices/${deviceId}/messages?data={"fields":"server.timestamp,escort.lls.value.2,escort.lls.value.3,din.1,din.2,din.3,timestamp,custom.param.306"}`);
      return response;
    }catch(err){
      console.log('Error fetching data from API')
    }
    
  },
  deviceTelemetry(deviceId) {
    return apiClient(`/gw/devices/${deviceId}/telemetry/device.name,din.1,din.2,din.3,escort.lls.value.2`)
  }
};
