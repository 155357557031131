<template>
    <div class="container">  
      <div class="row">        
      </div>          
        <div class="row justify-content-center">              
            <div class="col">
                <div class="card mb-3" style="max-width: 18rem;" v-if="!loading">
                    <h2 class="card-header">Login</h2>
                    <div class="card-body">
                        <form @submit.prevent="login()">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Email address</label>
                              <input v-model="username" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                              
                            </div>
                            <div class="form-group">
                              <label for="exampleInputPassword1">Password</label>
                              <input v-model="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
                            </div>
                            <div v-if="error" class="card bg-warning mb-3">                                
                                <span>Username or Password incorrect</span>
                            </div>
                            <button type="submit" class="btn btn-success">Login</button>
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Spinner -->
  <!-- Loader Dialog-->
  <v-dialog v-model="loginLoader" max-width="320" persistent>
                <v-list class="py-2" color="primary" elevation="12" rounded="lg">
                  <v-list-item prepend-icon="$vuetify-outline" title="Loading RMS Application...">
                    <template v-slot:prepend>
                      <div class="pe-4">
                        <v-img max-height="100" :src="require('../assets/volt_icon.png')"></v-img>
                      </div>
                    </template>

                    <template v-slot:append>
                      <v-progress-circular color="success" indeterminate="disable-shrink" size="42"
                        width="4"></v-progress-circular>
                    </template>
                  </v-list-item>
                </v-list>
              </v-dialog>
</template>
<script>
export default {
    name: 'LoginPage',
    components: {
        
    },
    data: () => {
        return {
            username: '',
            password: '',
            error: false,
            loginLoader: false,

        }
    },
    methods: {
      login() {
        console.log(this.$store)
        this.$store.dispatch("LOGIN", {
          username: this.username,
          password: this.password

        })
        .then(success => {
          this.$router.push("/dashboard");
        })
        .catch(error => {
          this.error = true;
        })
      }
    }
}
</script>
<style scoped>
.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
}
</style>