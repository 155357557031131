import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist'
import axios from "axios";

const vuexPersist = new VuexPersistence({
  key: 'user', // unique key for saving state properties in the browser storage
  storage: window.localStorage, // select the type of storage used (eg - localStorage, sessionStorage, etc)
  reducer: (state) => ({ user: state.user, token: state.token }) // select the state properties that you want persisted 
})

export default createStore({
  plugins: [vuexPersist.plugin],
  state: {
    token: localStorage.getItem('token') || null,
    user: localStorage.getItem('user') || null
  },
  getters: {
    isAuthenticated: state => !! state.token,
    getToken: state => state.token,
    getUser: state => state.user
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('authToken', token);
    },
    setUser(state, user) {
      state.user = user;
    },
    logout(state){
      state.token = null;
      state.user = null;      
      localStorage.clear();      
    }
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post(`https://rms-backend-6hdz.onrender.com/api/auth/login`, {
          username,
          password
        });
        const { token, user } = response.data;
        commit('setToken', token);
        commit('setUser', user);
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    },
    logout({ commit }) {      
      commit('logout');
    }
    },
    
})