<template>
  <v-container>
    <h1>Reports</h1>
    <v-row>
      <v-col md="4">
        <v-sheet color="#0E0856" class="pa-3 rounded-xl">
          <v-card>
            <v-card-title>
              Weekly Reports
            </v-card-title>
            <v-card-text>
              
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn>More</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col md="4">
        <v-sheet color="#92D050" class="pa-3 rounded-xl">
          <v-card>
            <v-card-title>
              Diesel Consumption
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>

                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn>More</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col md="4">
        <v-sheet color="#92D050" class="pa-3 rounded-xl">
          <v-card color="#0E0856" class="rounded-xl">
            <v-card-title>
              Custom Reports
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>

                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn>More</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>      
      <v-col cols>
        <h2>Refill Report</h2>
        <v-table class="custom-table">
          <thead>
            <tr>
              <th class="custom-header">Date</th>
              <th class="custom-header">Grid Uptime</th>
              <th class="custom-header">Gen Runtime</th>
              <th class="custom-header">SoB (Litres)</th>
              <th class="custom-header">CoB (Litres)</th>
              <th class="custom-header">Consumption (Litres)</th>
              <th class="custom-header">Refill (Litres)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in refillData" :key="data.timestamp" class="custom-row">
              <td>{{ formatTimestamp(data.begin) }}</td>
              <td>{{ convertToHHMMSS(data.Grid) }}</td>
              <td>{{ convertToHHMMSS(data.Gen) }}</td>
              <td>{{ data.start_volume }} liters</td>
              <td>{{ data.end_volume }}</td>
              <td>{{ data.total_diesel_consumption }}</td>
              <td>{{ data.refill_volume }} liters</td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import refillData from '@/services/api'

export default {
  components: {

  },
  data() {
    return {
      refillData: []

    };
  },
  async mounted() {
    this.fetchMessages()
  },
  methods: {
    async fetchMessages() {
      try {
        const response = await refillData.summary();        
        this.refillData = response.data.result        
      } catch (error) {
        console.error("Error Fetching Device Message")
      }
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000); // Convert to milliseconds
      const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      return date.toLocaleString('en-US', options);
    },
    convertToHHMMSS(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return `${this.padToTwoDigits(hrs)}:${this.padToTwoDigits(mins)}:${this.padToTwoDigits(secs)}`;
    },
    padToTwoDigits(num) {
            return num.toString().padStart(2, '0');
        },
  },

}
</script>

<style scoped>
.green-background {
    background-color: #92D050;
    color: white;
}

.red-background {
    background-color: red;
    color: white;
}

.rounded-lg {
    border-radius: 0.5rem;
    /* Equivalent to the 'lg' size in Tailwind CSS */
}

.table-responsive {
    overflow-x: auto;
}

.custom-table .custom-header {
    background-color: #92D050;
    color: #0E0856;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid white;
}

.custom-table .custom-row td {
    border-left: 2px solid #ddd;
}

.custom-table .custom-row td:first-child {
    border-left: none;
}
</style>