import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import DashboardView from "../views/DashBoard.vue";
import LocationDetail from "../views/LocationDetails.vue";
import ReportView from "@/views/ReportView.vue";
import LoginPage from "@/views/LoginPage.vue";
import AdminDashboard from "@/views/SuperUser/AdminDashboard.vue"
import ManagementView from "@/views/SuperUser/ManagementView.vue";
import LocationDetails from "@/views/SuperUser/LocationDetails.vue";
import store from "@/store"

//Clients Routes Imports Starts Here
import ClientAdmin from "@/views/Client/ClientAdmin.vue";
import appUserDash from "@/views/Client/App_User/appUserDash.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/locationdetail",
    name: "locationdetail",
    component: LocationDetail,
    meta: { requiresAuth: true},
  },
  {
    path: "/reports",
    name: "reportPage",
    component: ReportView,
    meta: {
      requiresAuth: true,
    },
  },
  // Super Users Routes

  {
    path: "/management",
    name: "admin_dashboard",
    component: AdminDashboard,
    meta: {
      requiresAuth: true,      
    },
  },
  {
    path: "/locations_management",
    name: "locations_management",
    component: ManagementView,
    meta: {
      requiresAuth: true,      
    },
  },
  {
    path: "/locations_detail",
    name: "locations_detail",
    component: LocationDetails,
    meta: {
      requiresAuth: true,      
    },
  },
  //---------------------------------------------

  /** Client Users Routes
   * Consists of Client_Admin and App_User
   */

  {
    path: "/client_admin",
    name: "client_admin",
    component: ClientAdmin,
    meta: { requiresAuth: true},
  },
  {
    path: "/app_user",
    name: "app_user",
    component: appUserDash,
    meta: { requiresAuth: true},
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name != 'home' && !store.getters.isAuthenticated)
    next({name: 'login'})
  else next()
})

export default router;
