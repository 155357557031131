<template>
    <v-container>        
        <LocationList @show-details="selectLocation" v-if="!selectedLocation" />
        <Location :selectedLocation="selectedLocation" :deviceId="deviceId" v-else />
    </v-container>
</template>
<script>
import Location from './LocationData.vue';
import LocationList from '../../components/LocationTable.vue';
import api from '@/services/api';
export default {
    components: {
        LocationList,
        Location
    },
    data () {
        return {
            selectedLocation: null,
            deviceId: null
        }
    },
    methods: {
        async selectLocation(deviceId){
            const response = await api.getLocation(deviceId);
            this.selectedLocation = response.data
            this.deviceId = deviceId;
        },
        goBack() {
            this.$back('back');
        }
    }
}
</script>
